import { SegmentKey } from 'shopify-payments-types'

const separator = '@'

export function parseShopId(accountName: string) {
  const [shopName, segmentKey] = accountName.split(separator)
  return { shopName, segmentKey } as {
    shopName: string
    segmentKey: SegmentKey
  }
}

export function makeShopId({
  shopName,
  segmentKey,
}: {
  shopName: string
  segmentKey: SegmentKey
}): string {
  return `${shopName}${separator}${segmentKey}`
}
