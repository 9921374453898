import { defaultConfig } from './Config'

export function isoDate(date: Date = new Date()) {
  return date.toISOString()
}

export function mapCurrencies<T>(
  mapper: (currency: string, current: T) => T | null,
  initial: Record<string, T> = {},
) {
  return defaultConfig.conversionCurrencies.reduce((acc, currency) => {
    const result = mapper(currency, acc[currency])
    if (result !== null) {
      acc[currency] = result
    }
    return acc
  }, initial)
}

export function prefixDataFields(data: Dictionary, prefix: string) {
  return Object.keys(data).reduce((acc, key) => {
    acc[`${prefix}${key}`] = data[key]
    return acc
  }, {} as Dictionary)
}
