import { TCallableFunctions } from 'shopify-payments-types'

export function getFirebaseFunction<TFuncName extends TCallableFunctions['name']>(
  name: TFuncName,
  cb: (name: TFuncName) => (data: Dictionary) => Promise<any>,
) {
  const func = cb(name)
  type TFunc = Extract<TCallableFunctions, { name: TFuncName }>
  return async (data: TFunc['data']): Promise<TFunc['result']> => {
    const ret = await func(data)
    return ret.data
  }
}
