import toPath from 'lodash.topath'

import { XFieldPath } from './xform.types'

// Assertions

/** @private is the given object a Function? */
export const isFunction = (obj: any): obj is Function =>
  typeof obj === 'function'

/**
 * Deeply get a value from an object via its path.
 */
export function getIn(obj: any, path: XFieldPath, def?: any) {
  let p = 0
  while (obj && p < path.length) {
    obj = obj[path[p++]]
  }
  return obj === undefined ? def : obj
}

export function fromPath(path: XFieldPath) {
  return path.reduce((acc: string, item) => {
    const prefix = acc === '' ? '' : '.'
    return acc + (isNaN(Number(item)) ? `${prefix}${item}` : `[${item}]`)
  }, '')
}

export { toPath }
