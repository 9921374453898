import { GoPay, SegmentKey, TMailerType, TSegmentConfig } from 'shopify-payments-types'

const mailTemplates: Record<TMailerType, string> = {
  signIn: 'd-90e2917a51df4f64b9de3466654d631b',
  signUp: 'd-339cdf62e5ab44c2bb9bf4c527a48805',
  usagePercent: 'd-3e58c377283b4e4e805b2b868e9e2f29',
  usageEnding: 'd-a39d1800386441208a6763f42fa746e5',
  invoice: 'd-c1cb3c742fe347f0b6707be5e8ea8370',
  invoiceDue: 'd-5a596500d0b2463aba3e5aeda3b8f5dd',
  invoiceOverDue: 'd-2645411434554982a350c56a14c560c9',
  missingBilling: 'd-b7d0044f3bcd48d3ad1cab6cc239c114',
  comgateActivation: 'd-3e7989e285cc4f3aa82c7e01a1f8ac74',
  activationReminder: 'd-14cdf834cfff4a57a2b54d615d9a7876',
  removalReminder: 'd-382274db565547d0a1d639959ed05cb6',
}

export const defaultConfig = {
  trialPeriodInDays: 14,
  usagePercentThreshold: [50, 75, 90],
  blockAccountAfterDaysOfOverdue: 7,
  usageCurrency: 'CZK',
  usageHistoryDFFormat: 'y-MM',
  conversionCurrencies: ['CZK', 'EUR', 'USD', 'GBP'],
  functionsRegion: 'europe-west1' as any,
  mailFrom: 'Platební brány.cz <integrace@soundsgood.agency>',
  mailTemplates,
  segments: {
    ...withSegment({
      key: 'gopay',
      label: 'GoPay',
      gatewayId: 1052051,
      endpoint: (isTest = true) =>
        isTest ? 'https://gw.sandbox.gopay.com/api' : 'https://gate.gopay.cz/api',
    }),
    ...withSegment({
      key: 'comgate',
      label: 'ComGate',
      gatewayId: 1054299,
      endpoint: () => 'https://payments.comgate.cz',
    }),
    ...withSegment({
      key: 'confirmo',
      label: 'Confirmo',
      gatewayId: 1055377,
      endpoint: () => 'https://confirmo.net/api/v3',
      isFree: true,
    }),
  } as Record<SegmentKey, TSegmentConfig>,
}

function withSegment(config: TSegmentConfig) {
  return { [config.key]: config }
}

export function getGoPayEnabledInstrumentsByDefault(): RoA<GoPay.TPaymentInstrument> {
  return ['PAYMENT_CARD', 'BANK_ACCOUNT', 'GPAY', 'PAYPAL', 'APPLE_PAY']
}

export type TConfig = typeof defaultConfig
