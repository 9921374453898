/// <reference types="@speedlo/types/react" />
import React from 'react'

import { useXForm } from './useXForm'
import { XFormConfig } from './xform.types'
import { XFormRender } from './XFormRender'

type TProps = XFormConfig<any> & SomeChildren

export const XForm: React.FC<TProps> = ({ children, ...config }) => {
  const xform = useXForm(config)
  return <XFormRender xform={xform}>{children}</XFormRender>
}
