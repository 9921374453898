/// <reference types="@speedlo/types/react" />
import React from 'react'

import { XFormContext } from './xform.types'
import { XFormProvider } from './XFormContext'

type TProps = SomeChildren & {
  xform: XFormContext<any>
}

export const XFormRender: React.FC<TProps> = ({ children, xform }) => {
  return (
    <form onSubmit={xform.handlers.handleSubmit}>
      <XFormProvider value={xform}>{children}</XFormProvider>
    </form>
  )
}
