import { SegmentKey } from 'shopify-payments-types'

import { defaultConfig } from './Config'

export const collections = {
  system: 'system',
  accounts: 'accounts',
  accountPayments: 'payments',
  accountHistory: 'history',
  accountUsages: 'historicUsage',
  register: 'registrations',
  users: 'users',
  userInvoices: 'invoices',
  userBillings: 'billings',
  userBills: 'bills',
}

export const systemDocs = {
  rates: 'rates',
}

export const segmentLabels: Record<SegmentKey, string> = {
  gopay: defaultConfig.segments['gopay'].label,
  comgate: defaultConfig.segments['comgate'].label,
  confirmo: defaultConfig.segments['confirmo'].label,
}

export function isValidSegment(segmentKey: string): segmentKey is SegmentKey {
  return Reflect.has(segmentLabels, segmentKey)
}

export const euCountries = [
  'ES',
  'BG',
  'HU',
  'LV',
  'PL',
  'GB',
  'CZ',
  'MT',
  'IT',
  'SI',
  'IE',
  'SE',
  'DK',
  'FI',
  'CY',
  'LU',
  'RO',
  'EE',
  'GR',
  'LT',
  'FR',
  'HR',
  'BE',
  'NL',
  'SK',
  'DE',
  'PT',
  'AT',
]
